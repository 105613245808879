import React, { useState } from 'react';
import styles from './ContactBar.module.css';
import copy from '../../img/copy_1.webp';
import copyDone from '../../img/copy_2.webp';
import { useClickOutside } from '../../hooks/useClickOutside';
import { openInNewTab } from '../../hooks/openInNewTab';
import { SlSocialInstagram, SlPhone } from "react-icons/sl";
import { AiOutlineMail } from "react-icons/ai";

const ContactDetail = (props) => {
  const [isCopied, setIsCopied] = useState(true);
  const menuRef = React.useRef();

  useClickOutside(menuRef, props.closeHandler);
  const copiedHandler = () => {
    navigator.clipboard.writeText('+420774786742');
    setIsCopied(!isCopied);
  };
  return (
    <div className={`${styles.index} ${styles.centerDiv}`}>
      <div className={styles.background} ref={menuRef}>
        <h2 className={styles.marginRight}>{props.contactName}</h2>
        <br />
        <h2 className={styles.marginRight}>{props.contact}</h2>
        {isCopied ? <img onClick={copiedHandler} className={styles.icon} src={copy} alt="kontakt na smartpush" /> : <img className={styles.icon} src={copyDone} alt="kontakt na smartpush" />}
      </div>
    </div>
  );
};
const ContactBar = () => {
  const [openHandler, setOpenHandler] = useState(false);
  const closeHandler = () => {
    setOpenHandler(!openHandler);
  };
  return (
    <>
      {openHandler && <ContactDetail closeHandler={closeHandler} contactName="David Kopl" contact="+420 774 786 742" />}
      <div className={styles.iconPosition}>
        <div onClick={() => openInNewTab('https://www.instagram.com/smart_push/')}>
        <SlSocialInstagram className={styles.iconTemplate} alt="hydroponics_aquaponics_bio"/>
          {/* <img className={styles.iconTemplate} src={} alt="hydroponics_aquaponics_bio" /> */}
        </div>
        <div>
        <SlPhone onClick={closeHandler} className={styles.iconTemplate} alt="kontakt telefon"/>
          {/* <img onClick={closeHandler} className={styles.iconTemplate} src="https://cdn-icons-png.flaticon.com/512/126/126509.png" alt="kontakt telefon" /> */}
        </div>
        <a href="mailto:kopldavid@seznam.cz">
        <AiOutlineMail color='black' className={styles.iconTemplate} alt="kontakt mail"/>
          {/* <img className={styles.iconTemplate} src="https://cdn-icons-png.flaticon.com/512/88/88042.png" alt="kontakt mail" /> */}
        </a>
      </div>
    </>
  );
};

export default ContactBar;
